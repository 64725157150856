import "@/registerServiceWorker";
import "@/assets/normalize.css";
import "@/assets/styles.scss";

import "@/assets/fontawesome/fontawesome.scss";
import "@/assets/fontawesome/solid.scss";
import "@/assets/fontawesome/regular.scss";

import PulseLoader from "vue-spinner/src/PulseLoader.vue"; //eslint-disable-line
import Notifications from "@kyvg/vue3-notification";

import { createApp } from "vue";
import router from "@/router";
import store from "@/store";
import App from "./App.vue";

const app = createApp(App);
app.use(Notifications).use(store).use(router).mount("#app");

app.component("PulseLoader", PulseLoader);
