import { Ref, onBeforeUnmount, onMounted } from "vue";

interface UseDetectOutsideClickReturn {
  listener: (event: MouseEvent) => void;
}

export default function useDetectOutsideClick(
  component: Ref,
  callback?: () => void,
): UseDetectOutsideClickReturn {
  const listener = (event: MouseEvent) => {
    if (event.target !== component.value && event.composedPath().includes(component.value)) {
      return;
    }
    if (typeof callback === "function") {
      callback();
    }
  };

  onMounted(() => {
    window.addEventListener("click", listener);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("click", listener);
  });

  return { listener };
}
