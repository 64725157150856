import Dexie, { Table } from "dexie";
import json from "@/assets/exercices.json";
import {
  Exercice, SeanceInDb, Performance, Commentaire,
} from "@/modules/models";

class MySubClassedDexie extends Dexie {
  exercices!: Table<Exercice>;
  seances!: Table<SeanceInDb>;
  performances!: Table<Performance>;
  commentaires!: Table<Commentaire>;

  constructor() {
    super("myDatabase");
    this.version(8).stores({
      exercices: "++id, nom, cible",
      seances: "++id, nom",
      performances: "++id, exercice_id, date, serie, [exercice_id+serie]",
      commentaires: "++id, exercice_id",
    });
  }
}

const db = new MySubClassedDexie();
db.on("populate", () => {
  db.transaction("rw", db.exercices, () => {
    json.forEach((exo) => {
      db.exercices.add({
        nom: exo.title,
        image: exo.slug,
        cibles: exo.cible.split(", "),
        informations: exo.info,
      });
    });
  });
});

export default db;
