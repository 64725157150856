// Merci ChatGPT
import { createApp, defineComponent, ref } from "vue";
import CustomModal from "@/components/CustomModal.vue";

const confirmModal = (message: string, callback: () => void) => {
  const modalContainer = document.createElement("div");
  document.body.appendChild(modalContainer);

  const app = createApp(
    defineComponent({
      name: "ConfirmModal",
      setup() {
        const isOpen = ref(true);
        const reactiveMessage = ref(message);

        const confirm = (confirmed: boolean) => {
          if (confirmed) {
            callback();
          }
          isOpen.value = false;
          document.body.removeChild(modalContainer);
        };

        const handleClose = (confirmed: boolean) => {
          confirm(confirmed);
          document.body.removeChild(modalContainer);
        };

        return {
          isOpen,
          message: reactiveMessage,
          confirm,
          handleClose,
        };
      },
      template: `
        <CustomModal :is-open="isOpen" @close="handleClose">
          <header>
            {{ message }}
          </header>
          <footer>
          <button
            type="button"
            class="cancel"
            @click="confirm(false)"
          >
            Annuler
          </button>
          <button
            type="button"
            class="success"
            @click="confirm(true)"
          >
            OK
          </button>
          </footer>
        </CustomModal>
      `,
    }),
  );

  app.component("CustomModal", CustomModal);

  app.mount(modalContainer);
};

export default confirmModal;
