import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import TrainingView from "@/views/TrainingView.vue";
import ExercicesView from "@/views/ExercicesView.vue";
import ExerciceDetailsView from "@/views/ExerciceDetailsView.vue";
import SeancesView from "@/views/SeancesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/training/",
    name: "resume-training",
    component: TrainingView,
  },
  {
    path: "/training/:id",
    name: "training",
    component: TrainingView,
  },
  {
    path: "/exercices",
    name: "exercices",
    component: ExercicesView,
  },
  {
    path: "/exercice/:id",
    name: "exercice",
    component: ExerciceDetailsView,
  },
  {
    path: "/seances",
    name: "seances",
    component: SeancesView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
