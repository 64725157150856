import { createStore } from "vuex";

export default createStore({
  state: {
    title: "",
    options: [],
    showMenu: false,
    isTrainingActive: localStorage.getItem("save") !== null,
    back: null,
    abortSeance: null,
    chronoEndingDate: null,
    chrono: 0,
  },
  getters: {
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setOptions(state, options) {
      state.options = options;
      if (!options.length) {
        state.showMenu = false;
      }
    },
    setShowMenu(state, showMenu) {
      state.showMenu = showMenu;
    },
    setIsTrainingActive(state, isTrainingActive) {
      state.isTrainingActive = isTrainingActive;
    },
    setBack(state, back) {
      state.back = back;
    },
    setAbortSeance(state, abortSeance) {
      state.abortSeance = abortSeance;
    },
    setChronoEndingDate(state, chronoEndingDate) {
      state.chronoEndingDate = chronoEndingDate;
    },
    setChrono(state, chrono) {
      state.chrono = chrono;
    },
  },
  actions: {
  },
  modules: {
  },
});
